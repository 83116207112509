import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { TableContainer, TableHeader, TableHeaderRow, TableHeaderCell, TableBody, TableRow, TableCell } from '@jsluna/table';
import { Tick, Cancel } from '@jsluna/icons';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#layout-principles"
            }}>{`Layout principles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#overview-of-grids"
            }}>{`Overview of grids`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#composition-of-grids"
            }}>{`Composition of grids`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#fluid-and-fixed-grids"
            }}>{`Fluid and fixed grids`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#spacing"
            }}>{`Spacing`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#breakpoints"
            }}>{`Breakpoints`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#click-and-touch-targets"
            }}>{`Click and touch targets`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#padding-and-scale-space"
            }}>{`Padding and scale space`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#aspect-ratios"
            }}>{`Aspect ratios`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#design-templates"
            }}>{`Design templates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#landmarks"
            }}>{`Landmarks`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <hr></hr>
    <h2 {...{
      "id": "layout-principles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#layout-principles",
        "aria-label": "layout principles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Layout principles`}</h2>
    <GridList columns="3" mdxType="GridList">
  <GridListItem title="Responsive" titleElement="h3" border mdxType="GridListItem">
    Our layout principles adapt to different screen sizes, so that users get a
    great experience regardless of what device they’re using.
  </GridListItem>
  <GridListItem title="Flexible" titleElement="h3" border mdxType="GridListItem">
    All of our defaults can be modified to meet the needs of different users.
  </GridListItem>
  <GridListItem title="Mobile first" titleElement="h3" border mdxType="GridListItem">
    In both design and code, we cater for the smallest breakpoint first, then
    customise as the screen size increases.
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "overview-of-grids",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#overview-of-grids",
        "aria-label": "overview of grids permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Overview of grids`}</h2>
    <p>{`The structure of a page is created through the grid. It controls the positioning of components, the spacing between them and how wide they can expand.`}</p>
    <p>{`The grid normally makes use of a 12-column layout to cater for either 2, 3 or 4 components being displayed side-by-side. However, there’s flexibility for other approaches if the design requires it.`}</p>
    <ImageWithCaption imageName="grid-overview" type="png" alt="grid overview image" mdxType="ImageWithCaption" />
    <p>{`Most measurements align to an 8 based grid applied, it's our base unit for mobile, tablet, and desktop. Smaller components, such as icons and type, can align to a 4 based grid.`}</p>
    <p>{`These base units will help us keep the design consistent and improve communication between designers and developers. We chose an 8 unit because most screen sizes are divisible by 4, and 4 itself is also an easily divisible number.`}</p>
    <hr></hr>
    <h2 {...{
      "id": "composition-of-grids",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#composition-of-grids",
        "aria-label": "composition of grids permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Composition of grids`}</h2>
    <p>{`The grid is made up of three different elements These are margins, columns, and gutters.`}</p>
    <h3>{`Grid margins`}</h3>
    <p>{`Margins are the spaces on the left and right edges of the device screen (shown below in red).
Margin widths are fixed within each breakpoint range.`}</p>
    <ImageWithCaption imageName="grid-margins" type="png" alt="grid margins image" mdxType="ImageWithCaption" />
    <h3>{`Grid columns`}</h3>
    <p>{`Columns show the space available for content or container to begin and end they are a vertical series within the grid (shown below in red). Content or containers can span multiple columns and column widths change with the size of the grid.`}</p>
    <ImageWithCaption imageName="grid-columns" type="png" alt="grid columns image" mdxType="ImageWithCaption" />
    <h3>{`Column layout span`}</h3>
    <p>{`Mix and match column widths of your content or containers by spanning multiple columns for a wide variety of layout options.`}</p>
    <ImageWithCaption imageName="column-span" type="png" alt="column span image" mdxType="ImageWithCaption" />
    <p>{`The above is an example of how a designer may vary columns of content or containers
across a 12-column grid layout within a page.`}</p>
    <ol>
      <li parentName="ol">{`Two pieces of content or containers, spaced equally across a layout.`}</li>
      <li parentName="ol">{`Three pieces of content or containers, spaced equally across a layout.`}</li>
      <li parentName="ol">{`Four pieces of content or containers, spaced equally across a layout.`}</li>
      <li parentName="ol">{`Six pieces of content or containers, spaced equally across a layout.`}</li>
      <li parentName="ol">{`Two pieces of content or containers of unequal width: one that spans three-fourths of the page, and the other spanning one fourth of the page.`}</li>
    </ol>
    <h3>{`Grid gutters`}</h3>
    <p>{`Gutters are the spaces between columns (shown below in red).
Gutter widths are fixed within each breakpoint range.`}</p>
    <ImageWithCaption imageName="grid-gutters" type="png" alt="grid gutters image" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "fluid-and-fixed-grids",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#fluid-and-fixed-grids",
        "aria-label": "fluid and fixed grids permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Fluid and fixed grids`}</h2>
    <p>{`Fluid grids are built using percentages for widths. As a result, columns are relative to one another allowing it to scale up and down fluidly.`}</p>
    <p>{`Fixed grids have a set width and resizing the browser or viewing it on different devices won't affect the way the website looks. `}</p>
    <p>{`While both fluid and fixed grid options can work for responsive websites, in most cases it's recommended to use fluid grids.`}</p>
    <h3>{`Fluid grids`}</h3>
    <p>{`Fluid layouts are calculated to their relative size, and stretch as the screen (viewport) is resized. For example, if you set layout width to 100%, each column will only be calculated to its relative size, and will stretch as the browser is resized.`}</p>
    <p>{`Fluid grid at 320px`}</p>
    <ImageWithCaption imageName="fluid-grid-320" type="png" alt="fluid grid 320px image" mdxType="ImageWithCaption" />
    <p>{`Fluid grid at 768px`}</p>
    <ImageWithCaption imageName="fluid-grid-768" type="png" alt="fluid grid 768px image" mdxType="ImageWithCaption" />
    <p>{`Fluid grid at 1024px`}</p>
    <ImageWithCaption imageName="fluid-grid-1024" type="png" alt="fluid grid 1024px image" mdxType="ImageWithCaption" />
    <h3>{`Fixed grids`}</h3>
    <p>{`Fixed grids use columns of a fixed size, with fluid margins to keep content unchanging within each breakpoint range. A fixed grid's layout can only change at an assigned breakpoint.`}</p>
    <p>{`Fixed grids at 1280px display`}</p>
    <ImageWithCaption imageName="fixed-grid-1280" type="png" alt="fixed grid 1280px image" mdxType="ImageWithCaption" />
    <p>{`Fixed grids at 1440px display`}</p>
    <ImageWithCaption imageName="fixed-grid-1440" type="png" alt="fixed grid 1440px image" mdxType="ImageWithCaption" />
    <p>{`Fixed grids at 2560px display`}</p>
    <ImageWithCaption imageName="fixed-grid-2560" type="png" alt="fixed grid 2560px image" mdxType="ImageWithCaption" />
    <h3>{`Content or container spanning`}</h3>
    <p>{`Make sure the content or container spans all of the columns in the layout (whether it is 2, 6, or 12 columns), rather than using columns as margins or padding. Following this methodology will keep layouts uniform and prevent unreliable responsive behaviour.`}</p>
    <ImageWithCaption imageName="container-spanning" type="png" alt="content or container image" mdxType="ImageWithCaption" />
    <ol>
      <li parentName="ol">{`Best practice for content or containers.`}</li>
      <li parentName="ol">{`Best practice for content or containers.`}</li>
      <li parentName="ol">{`Not recommended as this is relying on grid columns for margins, instead of the actual margins.`}</li>
    </ol>
    <hr></hr>
    <h2 {...{
      "id": "spacing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#spacing",
        "aria-label": "spacing permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Spacing`}</h2>
    <p>{`Spacing is the negative area between elements in your layout as well as within components.`}</p>
    <p>{`Our spacing system follows our grids system. Consistent spacing creates a visual balance that makes the user interface easier to scan. Apply consistent spacing to improve the quality of the user interfaces you produce.`}</p>
    <p>{`To create an efficient layout, we consider 2 kinds of spacings: horizontal and vertical. Horizontal based mainly on the grid systems and spacings through elements. Vertical based on some rules and minimum spacings between components.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="spacing-16-8-16" type="png" alt="vertical spacing example" mdxType="ImageWithCaption" />
    <p>
      Mostly all components align to an 8px and 16px square baseline grid for
      mobile, tablet and desktop.
    </p>
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <ImageWithCaption imageName="spacing-4px" type="png" alt="horizontal spacing example" mdxType="ImageWithCaption" />
    <p>
      Typography, iconography, and some elements within components can align to
      a 4px grid.
    </p>
  </GridListItem>
    </GridList>
    <h3>{`Spacing values`}</h3>
    <p>{`Consistent spacing is mandatory and we strongly recommend only using these sizes:`}</p>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Value</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Example of use</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">4px</TableCell>
      <TableCell mdxType="TableCell">The margin between icon + text</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">8px</TableCell>
      <TableCell mdxType="TableCell">The margin between label + input</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">12px</TableCell>
      <TableCell mdxType="TableCell">The padding within components</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">16px</TableCell>
      <TableCell mdxType="TableCell">The padding within components Most used spacing</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">24px</TableCell>
      <TableCell mdxType="TableCell">The margin between sections/organisms</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">32px</TableCell>
      <TableCell mdxType="TableCell">The margin between sections/organisms</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">40px</TableCell>
      <TableCell mdxType="TableCell">The margin between sections/organisms</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">48px</TableCell>
      <TableCell mdxType="TableCell">The margin between sections/organisms</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">56px</TableCell>
      <TableCell mdxType="TableCell">The margin between sections/organisms</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">64px</TableCell>
      <TableCell mdxType="TableCell">The margin between sections/organisms</TableCell>
    </TableRow>
  </TableBody>
    </TableContainer>
    <p>{`*`}{`Organisms are groups of molecules that work together to create a more complex component, such as a header or a footer. You create a more structured and scalable system by breaking down a design into its smallest components and then grouping them into molecules and organisms.`}</p>
    <h3>{`Visual example of vertical spacing values`}</h3>
    <ImageWithCaption imageName="vertical-spacing" type="png" alt="vertical spacing example" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "breakpoints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breakpoints",
        "aria-label": "breakpoints permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Breakpoints`}</h2>
    <p>{`Breakpoints allow for the layout to change on different screen sizes for web responsive interfaces, so that the experience is always optimised for the device the user’s viewing it on.`}</p>
    <h3>{`Example of breakpoint re-sizing`}</h3>
    <ImageWithCaption imageName="breakpoints-example" type="png" alt="breakpoint resizing example" mdxType="ImageWithCaption" />
    <p>{`Breakpoints allow for the layout to change on different screen sizes, so that the
experience is always optimised for the device the user’s viewing it on.`}</p>
    <p>{`For example, a product listing page might show 4 grid columns on a mobile device and 12 grid columns on a tablet and above. The design system has a core set of breakpoints that cover common device groups.`}</p>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Text</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Breakpoints</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">No. of columns</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Gutters</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Mobile</TableCell>
      <TableCell mdxType="TableCell">0px</TableCell>
      <TableCell mdxType="TableCell">4</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Tablet</TableCell>
      <TableCell mdxType="TableCell">600px</TableCell>
      <TableCell mdxType="TableCell">12</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Small desktop</TableCell>
      <TableCell mdxType="TableCell">900px</TableCell>
      <TableCell mdxType="TableCell">12</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Medium Desktop</TableCell>
      <TableCell mdxType="TableCell">1200px</TableCell>
      <TableCell mdxType="TableCell">12</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Large Desktop</TableCell>
      <TableCell mdxType="TableCell">1800px</TableCell>
      <TableCell mdxType="TableCell">12</TableCell>
      <TableCell mdxType="TableCell">24px</TableCell>
    </TableRow>
  </TableBody>
    </TableContainer>
    <hr></hr>
    <h2 {...{
      "id": "click-and-touch-targets",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#click-and-touch-targets",
        "aria-label": "click and touch targets permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Click and touch targets`}</h2>
    <p>{`Components within the design system use a standard height of 48px. Larger components go up to 80px.`}</p>
    <p>{`Clickable or touchable targets should be at least 48px by 48px.`}</p>
    <p>{`For smaller components like icons, you can use invisible padding to reach the target size.`}</p>
    <ImageWithCaption imageName="touch-targets" type="png" alt="click and touch targets example" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "padding-and-scale-space",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#padding-and-scale-space",
        "aria-label": "padding and scale space permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Padding and scale space`}</h2>
    <p>{`The design system uses the 8px base when adding horizontal and vertical space for layout objects, as well as internal padding within components.`}</p>
    <p>{`In circumstances where elements would look cramped on larger screen sizes, spacing can adapt based on what breakpoint grouping the device falls in.`}</p>
    <p>{`These values usually go up in multiples of 8px. But the spacing of elements can increase from 16px to 24px, depending on whether the user is on a phone or computer.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <p>16px padding on mobile (below 720)</p>
    <ImageWithCaption imageName="16-padding" type="png" alt="16px padding on mobile example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <p>24px padding example at larger size</p>
    <ImageWithCaption imageName="24-padding" type="png" alt="24px padding example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h3>{`Padding at different breakpoints`}</h3>
    <TableContainer className="ln-u-margin-bottom" mdxType="TableContainer">
  <TableHeader mdxType="TableHeader">
    <TableHeaderRow mdxType="TableHeaderRow">
      <TableHeaderCell mdxType="TableHeaderCell">Spacing name</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Breakpoints smaller than 720px</TableHeaderCell>
      <TableHeaderCell mdxType="TableHeaderCell">Breakpoints 720px or more</TableHeaderCell>
    </TableHeaderRow>
  </TableHeader>
  <TableBody mdxType="TableBody">
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">Default</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
      <TableCell mdxType="TableCell">24px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">SM</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
      <TableCell mdxType="TableCell">16px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">LG</TableCell>
      <TableCell mdxType="TableCell">24px</TableCell>
      <TableCell mdxType="TableCell">32px</TableCell>
    </TableRow>
    <TableRow mdxType="TableRow">
      <TableCell mdxType="TableCell">XL</TableCell>
      <TableCell mdxType="TableCell">1800px</TableCell>
      <TableCell mdxType="TableCell">40px</TableCell>
    </TableRow>
  </TableBody>
    </TableContainer>
    <hr></hr>
    <h2 {...{
      "id": "aspect-ratios",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#aspect-ratios",
        "aria-label": "aspect ratios permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Aspect ratios`}</h2>
    <p>{`Aspect ratios describe the relationship between width and height. To keep things looking consistent, use consistent aspect ratios.`}</p>
    <p>{`We recommend using 1:1, 2:3, 3:2, 3:4, 4:3, 9:16 and 16:9. These can apply to images, surfaces or components.`}</p>
    <ImageWithCaption imageName="aspect-ratio-layout" type="png" alt="aspect ratio example" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "design-templates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#design-templates",
        "aria-label": "design templates permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Design templates`}</h2>
    <p>{`Design templates can be found on the layout page of our Figma Libraries. They cover popular sizes for responsive web, iOS and Android.`}</p>
    <p>{`Use them as a starting point when creating a new design or presenting work on a specific device.`}</p>
    <ImageWithCaption imageName="design-templates" type="png" alt="design templates example" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "landmarks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#landmarks",
        "aria-label": "landmarks permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Landmarks`}</h2>
    <p>{`Screen reader users may not be able to see the visual structure of a page. Landmarks are used to provide shortcuts that allow screen reader users to jump to key sections.`}</p>
    <p>{`When a screen reader user navigates to a landmark, they'll be given a relevant description.`}</p>
    <h3>{`How to see landmarks on any page`}</h3>
    <p>{`We recommend you install the free `}<a parentName="p" {...{
        "href": "https://chrome.google.com/webstore/detail/accessibility-insights-fo/pbjjkligggfmakdaogkfomddhfmpjeni?hl=en"
      }}>{`Accessibility Insights Chrome extension`}</a>{`.
You can then turn on ‘landmarks’ within ‘ad hoc tools’.`}</p>
    <h3>{`How to use landmarks`}</h3>
    <p>{`You should make sure that the key landmarks, such as the header, navigation, main content and footer are present.`}</p>
    <p>{`They can be added to a page by using a semantic element or by adding a role attribute. These include (Component / HTML element / role attribute):`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<Header /> / <header /> / role="banner"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Navigation /> / <nav /> / role="navigation"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Main /> / <main /> / role="main"`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Footer /> / <footer /> / role="contentinfo"`}</inlineCode></li>
    </ul>
    <p>{`If there's more than one navigation landmark, use an aria-label to help the user to tell between them (for example, site navigation versus in-page navigation).`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/landmark_role"
      }}>{`Get more guidance on landmarks from MDN Web Docs`}</a>{`.`}</p>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around layouts or just help with your
  next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      